// import { Carousel } from "./../Service/Carousel.js"
// import { Preloader } from "./../Service/Preloader.js"
// import { Footer } from "./../Service/Footer.js"
// import { Counter } from "./../Service/Counter.js"
// import { Modal } from "./../Service/Modal.js"
// // import { Header } from "./../Service/Header.js"
// import { ScrollToTop } from "./../Service/ScrollToTop.js"
// import { Weather } from "./../Service/Weather.js"
// import { Message } from "./../Service/Message.js"
// import { Lector } from "./../Service/Lector.js"
// import { SearchModal } from "./../Service/SearchModal.js"
// import { Video } from "./../Service/Video.js"
// import { Animate } from "./../Service/Animate.js"
// import { Install } from "./../Service/Install.js"
// // import { Cookies } from "./../Service/Cookies.js"
// import { CancelHref } from "./../Service/CancelHref.js"
// import { Image } from "./../Service/Image.js"
// import { Switch } from "./../Service/Switch.js"
// import { Search } from "./../Service/Search.js"
// import { Ad } from "../Service/Ad.js"
// import { Newsletter } from "../Service/Newsletter.js"
// import { Print } from "../Service/Print.js"
// import { Pdf } from "../Service/Pdf.js"
// import { Accessibility } from "../Service/Accessibility.js"
// import { ContactForm } from "../Service/ContactForm.js"
// import { Calendar } from "../Service/Calendar.js"
// import { Languages } from "../Service/Languages.js"
import { Icons } from "../Service/Icons.js"
// import { CountdownTimer } from "../Service/CountdownTimer.js"
// import { KeyboardShortcuts } from "../Service/KeyboardShortcuts.js"
// import { KeyboardNavigator } from "../Service/KeyboardNavigator.js"
// import { FormSelectAction } from "../Service/FormSelectAction.js"
// import { Navigation } from "../Service/Navigation.js"
// import { WasteSchedule } from "../Service/WasteSchedule.js"
// import { Tabs } from "../Service/Tabs.js"
// import { WhereToDump } from "../Service/WhereToDump.js"

export class Application
{
    constructor(store)
    {
        this.store = store

        // this.store.service.Carousel = new Carousel()
        // this.store.service.Preloader = new Preloader()
        // this.store.service.Footer = new Footer()
        // this.store.service.Counter = new Counter()
        // this.store.service.Modal = new Modal(store)
        // // this.store.service.Header = new Header()
        // this.store.service.ScrollToTop = new ScrollToTop()
        // this.store.service.Weather = new Weather()
        // this.store.service.Message = new Message()
        // this.store.service.Lector = new Lector(store)
        // this.store.service.SearchModal = new SearchModal(store)
        // this.store.service.Video = new Video(store)
        // this.store.service.Animate = new Animate()
        // // this.store.service.Cookies = new Cookies()
        // this.store.service.CancelHref = new CancelHref()
        // this.store.service.Image = new Image()
        // this.store.service.Install = new Install()
        // this.store.service.Switch = new Switch()
        // this.store.service.Search = new Search()
        // this.store.service.Ad = new Ad(store)
        // this.store.service.Newsletter = new Newsletter(store)
        // this.store.service.Print = new Print(store)
        // this.store.service.Pdf = new Pdf(store)
        // this.store.service.Accessibility = new Accessibility()
        // this.store.service.ContactForm = new ContactForm(store)
        // this.store.service.Calendar = new Calendar(store)
        // this.store.service.Languages = new Languages()
        this.store.service.Icons = new Icons()
        // this.store.service.CountdownTimer = new CountdownTimer()
        // this.store.service.KeyboardShortcuts = new KeyboardShortcuts(store)
        // this.store.service.KeyboardNavigator = new KeyboardNavigator(store)
        // this.store.service.FormSelectAction = new FormSelectAction()
        // this.store.service.Navigation = new Navigation(store)
        // this.store.service.WasteSchedule = new WasteSchedule(store)
        // this.store.service.Tabs = new Tabs(store)
        // this.store.service.WhereToDump = new WhereToDump(store)
    }

    run()
    {
        // this.store.service.Carousel.run()
        // this.store.service.Carousel.watch()
        // this.store.service.Preloader.watch()
        // this.store.service.Footer.watch()
        // this.store.service.Counter.watch()
        // this.store.service.Modal.watch()
        // // this.store.service.Header.watch()
        // this.store.service.ScrollToTop.watch()
        // this.store.service.Weather.run()
        // this.store.service.Message.watch()
        // this.store.service.Message.run()
        // this.store.service.Lector.watch()
        // this.store.service.SearchModal.watch()
        // this.store.service.Video.watch()
        // this.store.service.Animate.run()
        // // this.store.service.Cookies.run()
        // // this.store.service.Cookies.watch()
        // this.store.service.CancelHref.watch()
        // this.store.service.Image.watch()
        // this.store.service.Install.run()
        // this.store.service.Switch.watch()
        // this.store.service.Search.watch()
        // this.store.service.Ad.run()
        // this.store.service.Newsletter.run()
        // this.store.service.Print.watch()
        // this.store.service.Pdf.watch()
        // this.store.service.Accessibility.run()
        // this.store.service.Accessibility.watch()
        // this.store.service.ContactForm.run()
        // this.store.service.Calendar.run()
        // this.store.service.Languages.run()
        this.store.service.Icons.run()
        this.store.service.Icons.watch()
        // this.store.service.CountdownTimer.watch()
        // this.store.service.KeyboardShortcuts.watch()
        // this.store.service.KeyboardNavigator.watch()
        // this.store.service.FormSelectAction.watch()
        // this.store.service.Navigation.watch()
        // this.store.service.WasteSchedule.run()
        // this.store.service.Tabs.run()
        // this.store.service.WhereToDump.run()
    }
}
